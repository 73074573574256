<template>
  <div
    v-if="showDataViewer"
    :style="{ height: reportHeight + 'px' }"
    v-resize="setHeight"
  >
    <HummingbirdReportViewer
      class="px-1"
      :key="report_key"
      report_type="property_rent_management"
      :show_divider="false"
      left_slot
      :grid-conf="gridConf"
      :column-conf="columnConf"
      @gridReady="(api) => (gridApi = api)"
    >
      <template v-slot:left>
        <div class="hb-font-header-3-medium">
          Grouping Profile:
          <span>{{ defaultGroupingProfile || "None" }}</span>
        </div>
      </template>
    </HummingbirdReportViewer>
  </div>
  <hb-empty-state v-else class="mt-4" :message="placeholderText" />
</template>
<script>
import HummingbirdReportViewer from "@/components/assets/HummingbirdReportViewer.vue";
import ClickCellRenderer from "@/components/BI/ClickCellRenderer.vue";

import { EventBus } from "../../../EventBus.js";
import { mapGetters } from "vuex";
import { startCase } from "lodash";
import api from "../../../assets/api.js";

export default {
  name: "RentPlanDefaults",

  components: {
    HummingbirdReportViewer,
    ClickCellRenderer,
  },

  props: {
    hasCautionBar: {
      type: Boolean,
      default: false,
    },
    rmPermissions: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },

  data() {
    return {
      report_key: 1,
      showRentIncreasePlans: false,
      reportHeight: 0,
      gridApi: null,
      emptyStateMessage: "",
      // spaceGroups: [],
      // spaceGroupLoading: false,
    };
  },
  watch: {
    selectedProperty: {
      async handler(val) {
        // Let it run on background
        if (val) {
          // await this.fetchPropertySpaceGroup();
          if (
            this.getPropertyRentSettings &&
            this.getPropertyRentSettings?.active
          ) {
            await this.fetchProfileAndConfigurations();
          }
        }
      },
      immediate: true,
    },
    hasCautionBar: {
      handler() {
        this.setHeight();
      },
      immediate: true,
    },
    rmPermissions: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.view_rent_plans && val.assign_rent_plan) {
          this.showRentIncreasePlans = true;
        } else this.showRentIncreasePlans = false;
        this.triggerTableRefresh();
      },
    },
  },

  computed: {
    ...mapGetters({
      properties: "propertiesStore/filtered",
      selectedProperty: "revManStore/getSelectedProperty",
      hasInternalRentEngine: "revManStore/hasInternalRentEngine",
      rentEngine: "revManStore/rentEngine",
      getPropertyRentSettings: "revManStore/getPropertyRentSettings",
      isRateManagementEnabled: "revManStore/isRateManagementEnabled",
      defaultGroupingProfile: "revManStore/getDefaultGroupingProfile",
    }),
    rentEngineMessage() {
      return !this.hasInternalRentEngine
        ? `Since you are using ${startCase(
            this.rentEngine
          )}, you cannot access this page`
        : "";
    },
    // getGroupingProfileName() {
    //   let groupingProfile = this.getPropertyRentSettings?.default_space_group_profile_id ?? '';
    //   let spaceGroup = this.spaceGroups.find(
    //     (group) => group.id === groupingProfile
    //   );
    //   return spaceGroup?.name ?? "";
    // },
    showLocalPropertySelector() {
      return this.properties?.length > 1;
    },
    // getGroupingProfileId() {
    //   return this.getPropertyRentSettings
    //     ? this.getPropertyRentSettings?.default_space_group_profile_id
    //     : "";
    // },
    showDataViewer() {
      return (
        this.selectedProperty &&
        this.defaultGroupingProfile &&
        this.isRateManagementEnabled &&
        // this.getGroupingProfileId &&
        this.hasInternalRentEngine &&
        this.getPropertyRentSettings?.active &&
        this.getPropertyRentSettings?.automation_enabled_by_admin
      );
    },
    gridConf() {
      return {
        props: {
          defaultColDef: {
            floatingFilter: false,
            wrapHeaderText: true,
            autoHeaderHeight: true,
          },
          overlayNoRowsTemplate:
            '<span style="font-size: 20px;padding: 10px;">No Space Groups</span>',
          animateRows: true,
          groupIncludeTotalFooter: true,
          getRowHeight: (params) =>
            params?.data?.queue_approval_deadline_left ? 60 : 48,
        },
      };
    },
    columnConf() {
      return {
        spacegroup_spacetype: {
          autoWidth: true,
          pinned: "left",
        },
        spacegroup_amenities: {
          autoWidth: true,
          pinned: "left",
        },
        spacegroup_size: {
          autoWidth: true,
          pinned: "left",
        },
        spacegroup_rent_increase_plans: {
          cellRenderer: "ClickCellRenderer",
          cellRendererParams: {
            type: "button",
            text: "View",
            color: "secondary",
          },
          lockPosition: "right",
          pinned: "right",
          sortable: false,
          filter: false,
          ...(!this.showRentIncreasePlans && {
            hide: true,
          }),
        },
      };
    },
    placeholderText() {
      let text = "";
      let isRentEnabled = this.getPropertyRentSettings?.active ?? false;
      let isRateEnabled = this.isRateManagementEnabled ?? false;
      if (this.selectedProperty) {
        // if (!this.getPropertyRentSettings?.default_space_group_profile_id && !this.spaceGroupLoading) {
        //   text = "Grouping Profile is not selected for this property.";
        // } else if (!this.hasInternalRentEngine) {
        //   text = this.rentEngineMessage;
        // }
        if (!isRentEnabled || !isRateEnabled || !this.defaultGroupingProfile) {
          text =
            "Automated rent management is currently not enabled for this property.";
        } else if (!this.getPropertyRentSettings?.automation_enabled_by_admin) {
          text =
            "You do not have the permission to access this page. Please contact the Admin.";
        } else if (!this.hasInternalRentEngine) {
          text = this.rentEngineMessage;
        }
      } else {
        text = "Select a property to manage Property Rent Changes.";
      }
      return text;
    },
  },

  methods: {
    setHeight() {
      let header = window.innerWidth < 1870 ? 15 : 5;
      let fixedComponents = 321;
      let propertySelector = this.showLocalPropertySelector ? 5 : -57;
      let cautionBar = -20;
      this.reportHeight =
        window.innerHeight -
        fixedComponents -
        cautionBar -
        propertySelector -
        header;
    },

    // async fetchPropertySpaceGroup() {
    //   this.spaceGroupLoading = true;
    //   this.spaceGroups = [];
    //   try {
    //     let response = await api.get(
    //       this,
    //       api.PROPERTIES + this.selectedProperty + "/space-groups"
    //     );
    //     this.spaceGroups = response.spaceGroups;
    //   } catch (err) {
    //     console.error("Space group fetch error", err);
    //   }
    //   this.spaceGroupLoading = false;
    // },

    parentMethod(data = {}) {
      let cellData = data?.data ?? {};
      EventBus.$emit("show-rent-plan-defaults", {
        ...cellData,
        ...{ property: this.selectedProperty },
      });
    },

    setDynamicRuntimeProperties(data = null) {
      this.$store.commit("reportStore/setDynamicRunParam", {
        propertyIDArray: data ? [data] : undefined,
      });
    },

    /**
     * Fetch Rent management configurations for property.
     * Note: as per hb implementation 'dynamicRun' query is needed for passing property key
     */
    triggerTableRefresh(columns = []) {
      if (!this.$route.query.dynamicRun) {
        this.$router.push({ path: "", query: { dynamicRun: true } });
      }
      this.setDynamicRuntimeProperties(this.selectedProperty);
      // Refresh table data
      this.gridApi?.refreshServerSide({ route: columns, purge: true });
    },

    /**
     * Fire configuration fetch and update table
     */
    async fetchProfileAndConfigurations() {
      if (!this.selectedProperty) return;
      try {
        this.triggerTableRefresh();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.profile-loader {
  margin-top: 6px;
}
</style>
