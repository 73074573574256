<template>
  <div
    :style="{ height: reportHeight + 'px' }"
    v-resize="setHeight"
    class="pt-4"
  >
    <hb-report
      ref="report"
      v-if="showDataViewer"
      :key="report_key"
      report_type="rent_change_deployment_month"
      :show_divider="false"
      :grid-conf="gridConf"
      :column-conf="columnConf"
    />
    <hb-empty-state v-else :message="getEmptyTextMsg" />
  </div>
</template>
<script>
import HbReport from "@/components/assets/HummingbirdReportViewer.vue";
import MultiLineRenderer from "@/components/BI/MultiLineRenderer.vue";
import StatusCellRenderer from "@/components/BI/StatusCellRenderer.vue";
import RentChangeQueueActionBtn from "@/components/revenue_management/rent_management/RentChangeQueueActionBtn.vue";

import { EventBus } from "../../../EventBus.js";
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash";

export default {
  name: "RentChangeQueue",

  components: {
    HbReport,
    MultiLineRenderer,
    StatusCellRenderer,
  },

  props: {
    rmPermissions: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },

  data() {
    return {
      report_key: 1,
      reportHeight: 0,
    };
  },

  watch: {
    selectedProperty: {
      handler(prev, next) {
        this.fetchProfileAndConfigurations();
        //code to reset applied filters rentchange queue when property changes
        if (this.$refs?.report) this.$refs?.report?.resetReport();
      },
      immediate: true,
    },
  },

  mounted() {
    EventBus.$on("rentChangeQueueEvents", (event) => {
      if (event == "refreshTable") this.triggerTableRefresh();
    });
  },
  beforeDestroy() {
    EventBus.$off("rentChangeQueueEvents");
  },

  computed: {
    ...mapGetters({
      properties: "propertiesStore/filtered",
      selectedProperty: "revManStore/getSelectedProperty",
      getPropertyRentSettings: "revManStore/getPropertyRentSettings",
    }),
    showLocalPropertySelector() {
      return this.properties?.length > 1;
    },
    getEmptyTextMsg() {
      let text = "";
      if (!this.selectedProperty) {
        text =
          "Please select a property from the dropdown above to view and manage current, past and upcoming rent changes.";
      } else {
        text = "Rent Management is not enabled for this Property";
      }
      return text;
    },
    showDataViewer() {
      return this.selectedProperty && this.getPropertyRentSettings?.active;
    },
    gridConf() {
      return {
        props: {
          BIHelpers: {
            custom: {
              enabled: true,
              icon: "mdi-dots-vertical",
              component: {
                definition: RentChangeQueueActionBtn,
                props: {
                  permissions: this.rmPermissions,
                  filterdProperties: cloneDeep(this.properties),
                },
              },
              action: "click",
            },
          },
          defaultColDef: {
            floatingFilter: false,
            wrapHeaderText: true,
            autoHeaderHeight: true,
          },
          overlayNoRowsTemplate:
            '<span style="font-size: 20px;padding: 10px;">No Rent Changes</span>',
        },
      };
    },
    columnConf() {
      return {
        rent_change_over_under_sell_rate: {
          filter: false,
        },
        rent_change_deployment_month: {
          cellRenderer: (params) =>
            `<span style="font-weight: 500">${params.valueFormatted}</span>`,
          pinned: "left",
        },
        rent_change_approval_deadline_date: {
          cellRenderer: "MultiLineRenderer",
          cellRendererParams: {
            fields: [
              {
                column: "rent_change_approval_deadline_date",
                formatter: (val) =>
                  val && val != "N/A"
                    ? new Date(val).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })
                    : "N/A",
              },
              {
                column: "rent_change_approval_deadline_left",
                color: (data) =>
                  data?.rent_change_approval_deadline_left < 5 ? "red" : "grey",
                font_size: "12px",
                formatter: (val) =>
                  val > 0
                    ? val == 1
                      ? `${val} Day left `
                      : `${val} Days left `
                    : "",
                tooltip: true,
              },
            ],
          },
          // autoHeight: true
        },
        rent_change_total_rent_increase: {
          cellRenderer: (params) => {
            if (params.valueFormatted) {
              let value = params.valueFormatted.split("$")[1];
              let colorData =
                params.valueFormatted.charAt(0) === "-"
                  ? 'red">'
                  : parseFloat(value) > 0
                  ? 'green">+'
                  : '#637381">+';
              return `<span style="color: ${colorData}${params.valueFormatted}</span>`;
            } else return "";
          },
        },
        rent_change_approval_status: {
          cellRenderer: "StatusCellRenderer",
          cellRendererParams: {
            general: true,
          },
          pinned: "right",
        },
      };
    },
  },

  methods: {
    setHeight() {
      let header = window.innerWidth < 1870 ? 20 : -5;
      let stepper = 100;
      let propertySelector = this.showLocalPropertySelector ? -35 : 53;
      let footer = 91;
      let heading = 61;
      let padding = 20;
      this.reportHeight =
        window.innerHeight -
        header -
        stepper -
        heading -
        footer -
        padding +
        propertySelector;
    },

    showReviewChanges(params) {
      EventBus.$emit("show-rent-review", {
        data: params,
      });
    },

    setDynamicRuntimeProperties(data = null) {
      this.$store.commit("reportStore/setDynamicRunParam", {
        propertyIDArray: data ? [data] : undefined,
      });
    },

    triggerTableRefresh(data = {}, columns = []) {
      if (!this.$route.query.dynamicRun) {
        this.$router.push({ path: "", query: { dynamicRun: true } });
      }
      this.setDynamicRuntimeProperties(this.selectedProperty);
      EventBus.$emit("unit_edited");
    },
    /**
     * Fire configuration fetch and update table
     */
    async fetchProfileAndConfigurations() {
      if (!this.selectedProperty) return;
      this.loading = true;
      try {
        this.triggerTableRefresh();
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
  },
};
</script>
