<template>
  <div>
    <div class="mt-4" v-if="notification && !hasInternalRentEngine">
      <hb-notification
        v-model="notification"
        title="Note:"
        type="caution"
        @close="setHeight"
      >
        <span v-html="notificationMessage"></span>
      </hb-notification>
    </div>
    <div
      class="rent-management-reports pb-1"
      :style="{ height: reportHeight + 'px' }"
      v-resize="setHeight"
    >
      <hb-report
        v-if="showDataViewer"
        report_type="tenant_rent_management"
        :actions_panel="['bulk_edit', 'export', 'columns']"
        row_click="unit_view"
        :grid-conf="gridConf"
        :column-conf="columnConf"
        :show_divider="false"
        left_slot
        show_search
        :default_column_filters="column_filters"
        @rowClicked="viewTenantProfiles($event)"
        @gridReady="(api) => (gridApi = api)"
      />
      <hb-empty-state v-else class="mt-4" :message="getEmptyTextMsg" />
    </div>
    <assign-rent-plan
      ref="changeRentPlanModal"
      :is-value-tiered="false"
      @selected="updateChangedPlan($event)"
      @close="clearRowLeaseId()"
    />
    <exempt-rent-change
      ref="exemptRent"
      :property="selectedProperty"
      @updateExemptStatus="updateExemptStatus($event)"
      @close="clearRowLeaseId()"
    />
    <skip-cancel-rent
      :property="selectedProperty"
      :skipCancelData="skipCancelData"
      :rent-change-id="getSkipCancelId"
      :showSkipCancel="skipCancelNextRent"
      :report="true"
      @closeSkip="closeSkipCancel($event)"
    />
    <!-- Manual/Edit Rent change -->
    <RentChangeModal
      :ids="id"
      :rentData="rentChangeData"
      :currentRent="rentChangeData.currentRent"
      :title="rentChangeTitle"
      :edit="isEdit"
      :manualRentChangeModal="showManualRentChangeModal"
      @closeModal="closeRentChangeModal()"
    />
    <hb-modal
      v-model="showPopUp"
      size="medium"
      :title="popupTitle"
      :footerOff="true"
      confirmation
      @close="clearRowLeaseId()"
      show-help-link
    >
      <template v-slot:content>
        <div class="px-6 py-4">
          <div class="pb-2">
            {{ popupDescription }}
          </div>
        </div>
      </template>
    </hb-modal>
  </div>
</template>

<script>
import HbReport from "@/components/assets/HummingbirdReportViewer.vue";
import AssignRentPlan from "./AssignRentPlan.vue";
import TenantRentKebabFilter from "./TenanatRentKebabFilter.vue";
import ExemptRentChange from "./ExemptRentChange.vue";
import SkipCancelRent from "./SkipCancelRent.vue";
import RentChangeModal from "./RentChangeModal.vue";

import UnitNumberCellRenderer from "@/components/BI/UnitNumberCellRenderer.vue";
import MultiLineRenderer from "@/components/BI/MultiLineRenderer.vue";
import StatusCellRenderer from "@/components/BI/StatusCellRenderer";
import PlanCellRenderer from "@/components/BI/PlanCellRenderer";

import { mapGetters, mapState } from "vuex";
import { EventBus } from "../../../EventBus.js";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import { startCase } from "lodash";

import api from "../../../assets/api.js";

export default {
  name: "TenantRentManagement",

  components: {
    HbReport,
    UnitNumberCellRenderer,
    PlanCellRenderer,
    StatusCellRenderer,
    MultiLineRenderer,
    ExemptRentChange,
    AssignRentPlan,
    SkipCancelRent,
    RentChangeModal,
  },

  mixins: [notificationMixin],

  props: {
    rmPermissions: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },

  data() {
    return {
      gridApi: null,
      reportHeight: 0,
      skipCancelNextRent: false,
      skipCancelData: {},
      showManualRentChangeModal: false,
      id: [],
      leaseId: "",
      rentChangeData: {},
      isEdit: false,
      rentChangeTitle: "",
      notification: true,
      showPopUp: false,
      popupTitle: "",
      popupDescription: "",
      column_filters: [
        {
          id: "lease_rent_plan_status",
          value: ["active", "no-status"]
        },
      ]
    };
  },

  mounted() {
    let events = {
      changeRentPlan: this.changeRentPlan,
      rentChangeExempt: this.rentChangeExempt,
      refreshTable: this.triggerTableRefresh,
      editNextRent: this.editNextRent,
      skipNextRent: this.skipNextRent,
      manualRentChange: this.manualRentChange,
    };
    EventBus.$on("tenantRentManagementEvent", ({ event, data }) => {
      this.skipCancelData = data;
      if (events[event]) events[event](data);
    });
    this.$store.commit(
      "reportStore/setTenantReportViews",
      "tenant_rent_management"
    );
  },

  watch: {
    selectedProperty: {
      handler(prev, next) {
        this.fetchProfileAndConfigurations();
      },
      immediate: true,
    },
    hasInternalRentEngine: {
      handler(val) {
        this.setHeight();
      },
    },
  },

  computed: {
    ...mapGetters({
      properties: "propertiesStore/filtered",
      selectedProperty: "revManStore/getSelectedProperty",
      hasInternalRentEngine: "revManStore/hasInternalRentEngine",
      getPropertyRentSettings: "revManStore/getPropertyRentSettings",
      rentEngine: "revManStore/rentEngine",
    }),
    ...mapState({
      rentPlans: (state) => state.revManStore?.rent?.plans ?? [],
    }),
    getPermissions() {
      let permissions = {
        view_rent_plans: "",
        exempt_rent_changes: "",
        manage_rent_change_status: "",
        manual_rent_changes: "",
        assign_rent_plan: "",
      };
      for (let key in permissions) {
        permissions[key] = this.rmPermissions[key];
      }
      return permissions;
    },
    hasPermissions() {
      return (
        Object.keys(this.getPermissions).length &&
        !Object.values(this.getPermissions).every((value) => value === false)
      );
    },
    notificationMessage() {
      return `Since you are using <strong>${startCase(
        this.rentEngine
      )}</strong>, you cannot update the Rent Plans`;
    },
    getEmptyTextMsg() {
      let text = "";
      if (!this.selectedProperty) {
        text =
          "Welcome to our Property Rent Management page! Select your property from the dropdown above to effortlessly manage tenant rents. Here, you can update rental rates, track payments, exempt tenants, and perform other essential tasks with ease. Get started now!";
      } else {
        text = "Rent Management is not enabled for this Property";
      }
      return text;
    },
    showDataViewer() {
      return this.selectedProperty && this.getPropertyRentSettings?.active;
    },
    showLocalPropertySelector() {
      return this.properties?.length > 1;
    },
    getSkipCancelId() {
      return this.skipCancelData?.tenant_rent_change_id ?? "";
    },
    gridConf() {
      return {
        props: {
          ...(this.hasPermissions && {
            BIHelpers: {
              kebab: {
                enabled: true,
                icon: "mdi-dots-vertical",
                component: {
                  definition: TenantRentKebabFilter,
                  props: {
                    parentEvent: "tenantRentManagementEvent",
                    permissions: this.getPermissions,
                  },
                },
                action: "click",
              },
            },
          }),
          getRowId: (params) => params.data.lease_id,
          defaultColDef: {
            floatingFilter: false,
            wrapHeaderText: true,
            autoHeaderHeight: true,
          },
        },
      };
    },
    columnConf() {
      return {
        tenant_name: {
          pinned: "left",
          autoWidth: true,
        },
        tenant_space: {
          cellRenderer: "UnitNumberCellRenderer",
        },
        tenant_selected_rent_plan: {
          cellRenderer: "PlanCellRenderer",
          pinned: "right",
          hide:
            !this.hasInternalRentEngine ||
            !this.getPropertyRentSettings?.automation_enabled_by_admin,
          cellRendererParams: {
            type: "rent",
            showPlanData:
              this.rmPermissions?.view_rent_plans &&
              this.rentPlans &&
              this.rentPlans.length,
          },
        },
        lease_rent_plan_status: {
          cellRenderer: "StatusCellRenderer",
          pinned: "right",
          lockPosition: "right",
          suppressNavigable: true,
          cellRendererParams: {
            leaseStatus: true,
            general: true,
          },
        },
        tenant_scheduled_rent_change: {
          hide: !this.getPropertyRentSettings?.automation_enabled_by_admin,
        },
      };
    },
  },

  beforeDestroy() {
    this.setDynamicRuntimeProperties(null);
    EventBus.$off("tenantRentManagementEvent");
  },

  methods: {
    setHeight() {
      let header = window.innerWidth < 1870 ? 25 : 5;
      let stepper = 100;
      let propertySelector = this.showLocalPropertySelector ? -18 : 70;
      let footer = 90;
      let heading = 60;
      let padding = 40;
      let dataRows = this.showDataViewer ? 0 : 15;
      let notification =
        this.notification && !this.hasInternalRentEngine ? 45 : -15;
      this.reportHeight =
        window.innerHeight -
        header -
        notification -
        dataRows -
        stepper -
        heading -
        footer -
        padding +
        propertySelector;
    },

    viewTenantProfiles(data) {
      this.$store.dispatch("navigationStore/openSlideOut", {
        component: "unit",
        props: {
          unit_id: data.unit_id,
        },
      });
    },

    clearRowLeaseId() {
      if (this.showPopUp) this.showPopUp = false;
      this.updateRowData(this.leaseId);
      this.leaseId = "";
    },

    async editNextRent(data) {
      this.leaseId = data.lease_id;
      if (data?.tenant_rent_change_id) {
        if (
          this.showAuctionPopup(data?.tenant_auction_status, "edit rent change")
        )
          return;
        this.isEdit = true;
        this.rentChangeTitle = "Edit Rent Change";
        this.rentChangeData = {
          currentRent: data.lease_rent,
          newRent: data.tenant_new_rent,
          deploymentDate: data.tenant_next_rent_change,
          affectAutomaticRent: !!data.tenant_affect_timeline,
          lastChange: data.last_change ?? "",
          sellRate: data.unit_price,
          setRate: data.unit_set_rate,
          variance: data.lease_rent - data.unit_price,
          variancePrct: data.lease_rent_variance_prct * 100,
        };
        this.showManualRentChangeModal = true;
        this.id = [data.tenant_rent_change_id];
      } else {
        this.popupTitle = "No Rent Change";
        this.popupDescription =
          "The selected tenant doesn't have a Rent change to Edit.";
        this.showPopUp = true;
      }
    },

    closeRentChangeModal() {
      this.showManualRentChangeModal = false;
      if (this.isEdit) {
        this.isEdit = false;
      }
      this.clearRowLeaseId();
    },

    closeSkipCancel(data) {
      this.skipCancelNextRent = false;
      if (data.status) {
        this.triggerTableRefresh();
        EventBus.$emit("rentChangeQueueEvents", "refreshTable");
      } else this.clearRowLeaseId();
    },

    setDynamicRuntimeProperties(data = null) {
      this.$store.commit("reportStore/setDynamicRunParam", {
        propertyIDArray: data ? [data] : undefined,
      });
    },

    triggerTableRefresh(data = {}, columns = []) {
      if (!this.$route.query.dynamicRun) {
        this.$router.push({ path: "", query: { dynamicRun: true } });
      }
      this.setDynamicRuntimeProperties(this.selectedProperty);
      EventBus.$emit("unit_edited");
    },

    updateRowData(pKey, data = {}) {
      let node = null;
      if (this.gridApi) node = this.gridApi.getRowNode(pKey);
      if (node) node.setData({ ...node.data, ...data });
    },
    /**
     * Fire configuration fetch and update table
     */
    async fetchProfileAndConfigurations() {
      if (!this.selectedProperty) return;
      this.loading = true;
      try {
        this.triggerTableRefresh();
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },

    /**
     * Function to open change rent plans modal
     */

    async changeRentPlan(data) {
      this.leaseId = data.lease_id;
      await this.$refs?.changeRentPlanModal
        .show({
          rentPlans: this.rentPlans.length ? this.rentPlans : [],
          currentPlanId: data?.tenant_rent_plan_id ?? "",
          rowData: { ...data },
        })
        .catch(() => ({ error: true }));
    },

    /**
     * Function for changing selected rent plan (API call)
     */

    async updateChangedPlan(data) {
      let body = {
        rent_plan_id: data.selectedPlanId,
      };
      await api
        .put(
          this,
          api.getPropertyRentManagementUrl(this.selectedProperty) +
            `leases/${data.leaseId}/plan`,
          body
        )
        .then(() => {
          this.showMessageNotification({
            type: "success",
            description: "You have successfully updated Rent Plan.",
          });
          this.updateRowData(data.leaseId, {
            tenant_selected_rent_plan: data.selectedPlanLabel,
            tenant_rent_plan_id: data.selectedPlanId,
            ...(!data.rowData.lease_rent_plan_status && {
              lease_rent_plan_status: "active",
            }),
          });
        })
        .catch((error) => {
          console.log(error);
          this.showMessageNotification({
            type: "error",
            description: "Rent Plan update failed.",
          });
          this.clearRowLeaseId();
        });
    },
    /**
     * function to show exempt updation status and update table rows
     */

    async updateExemptStatus(data) {
      // Show a message notification with the given type and description
      this.showMessageNotification({
        type: data.type,
        description: data.description,
      });

      // If the message type is "success"
      if (data.type === "success") {
        // If hummingbird rent engine is being used
        if (this.hasInternalRentEngine) {
          /**
           * Determine the lease rent plan status based on the rent plan and status type
           *  if status type is active and there are is rent plan clear the field else update status type
           */
          const lease_rent_plan_status =
            (!data.rentPlan || data.rentPlan === "-") &&
            data.statusType === "active"
              ? ""
              : data.statusType;
          // Update the row data with the new lease rent plan status
          // this.updateRowData(data.leaseId, { lease_rent_plan_status });
          EventBus.$emit("unit_edited");
        } else {
          /**
           * Determine the lease rent plan status based on the rent plan and status type
           *  if status type is active clear the field else update status type
           */
          const lease_rent_plan_status =
            data.statusType === "active" ? "" : data.statusType;
          // Update the row data with the new lease rent plan status
          // this.updateRowData(data.leaseId, { lease_rent_plan_status });
          EventBus.$emit("unit_edited");
        }

        // If the status type is "exempt"
        if (data.statusType === "exempt") {
          // Refresh Tenant Rent Management table
          this.triggerTableRefresh();
        }

        // Emit a "refreshTable" event on the EventBus
        EventBus.$emit("rentChangeQueueEvents", "refreshTable");
      } else {
        // Clear the row lease ID
        this.clearRowLeaseId();
      }
    },

    /**
     * Function to open rent exempt modal
     */
    async rentChangeExempt(data) {
      this.leaseId = data.lease_id;
      await this.$refs.exemptRent.show(
        data,
        data?.lease_id,
        "",
        data?.lease_rent_plan_status
      );
    },

    async skipNextRent(data) {
      this.leaseId = data.lease_id;
      if (data?.tenant_rent_change_id) {
        if (
          this.showAuctionPopup(data?.tenant_auction_status, "skip/cancel rent")
        )
          return;
        this.skipCancelNextRent = true;
      } else {
        this.popupTitle = "No Rent Change";
        this.popupDescription =
          "The selected tenant doesn't have a Rent change to Skip/Cancel.";
        this.showPopUp = true;
      }
    },

    async manualRentChange(data) {
      if (this.showAuctionPopup(data?.tenant_auction_status, "change rent"))
        return;
      this.rentChangeTitle = "Manual Rent Change";
      this.leaseId = data.lease_id;
      this.rentChangeData = {
        currentRent: data.lease_rent,
        lastChange: data.last_change ?? "",
        sellRate: data.unit_price,
        setRate: data.unit_set_rate,
        variance: data.lease_rent - data.unit_price,
        variancePrct: data.lease_rent_variance_prct * 100,
      };
      this.showManualRentChangeModal = true;
      this.id = [data.lease_id];
    },

    isAuctioned(auctionStatus) {
      return ["move_out", "auction_payment"].includes(auctionStatus ?? "");
    },

    showAuctionPopup(auctionStatus, msg) {
      if (this.isAuctioned(auctionStatus)) {
        this.popupTitle = "Auctioned Tenant";
        this.popupDescription = `Cannot ${msg} for auctioned tenant.`;
        this.showPopUp = true;
        return true;
      }
      return false;
    },
  },
};
</script>
<style lang="scss" scoped></style>
