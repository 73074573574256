<script type="text/babel">
import { EventBus } from "../../../EventBus.js";

export default {
  name: "RentChangeQueueActionBtn",
  props: {
  },
  computed: {
    buttonProps() {
      let buttonType = ["to approve"].includes(
        this.params?.data?.rent_change_approval_status?.toString().toLowerCase()
      )
        ? "review"
        : "view";
      return {
        review: {
          label: "Review",
          color: "primary",
          small: true,
        },
        view: {
          label: "View",
          color: "secondary",
          small: true,
        },
      }[buttonType];
    },
  },
  methods: {
    triggerEvent() {
      EventBus.$emit("show-rent-review", {
        data: this.params.data,
        filterdProperties: this.params?.component?.userDefinedProps?.filterdProperties,
        rent_management_Permissions: this.params?.component?.userDefinedProps?.permissions,
      });
    },
  },
};
</script>

<template>
  <div>
    <hb-btn v-bind="buttonProps" @click="triggerEvent">
      {{ buttonProps.label }}
    </hb-btn>
  </div>
</template>
