<template>
  <div>
    <right-side-navigation
      v-model="selectedSideTab"
      :menu-items="rightSideMenuItems"
      :hasHeader="true"
    >
      <template v-slot:component>
        <div :class="{ 'mt-3': showRentPlansNotification ,'mb-3' : selectedSideTab != 'rentPlanDefaults'}">
          <div>
            <!-- component to show a notification message note in caution bar   -->
            <hb-notification
              v-if="showRentPlansNotification"
              v-model="cautionBar"
              type="caution"
              :have-title="false"
              @close="cautionBar = false"
            >
              <strong>Note: </strong>Rent Plans are managed on a company level.
              Any changes you make will affect other properties as well.
            </hb-notification>
            <template v-if="selectedSideTab == 'rentPlanLibrary'">
              <span class="rent-card-wrap pl-2 pt-2" v-if="initialDataLoading">
                <span v-for="n in 8" :key="n" class="loader-card">
                  <v-skeleton-loader
                    type="card-heading, list-item-two-line, image, image"
                  />
                </span>
              </span>
              <!-- component to display when no rent plans available, has option for adding new rent plan, has no permmissions -->
              <hb-empty-state
                v-else-if="showEmptyStateView"
                :message="planPlaceholderMsg"
                :showBtn="showAddButton"
                class="mt-4"
                btnTxt="Add Rent Plan"
                @clicked="showCreatePlanForm = !showCreatePlanForm"
              />

              <div v-else>
                <!-- Rent plan listing area and an option for create new plan -->
                <hb-data-table-header v-if="rmPermissions.manage_rent_plans">
                  <template v-slot:description>
                    Effortlessly manage corporate-level rent plans with tailored controls for minimum and maximum rent increases, rent caps, and customizable timelines. Create rent plans with the frequency of your choice, whether it's multiple rent changes or a single recurring rent adjustment.
                  </template>
                  <template v-slot:actions>
                    <hb-btn
                      color="secondary"
                      small
                      @click="showCreatePlanForm = !showCreatePlanForm"
                      prepend-icon="mdi-plus"
                    >
                      Add Rent Plan
                    </hb-btn>
                  </template>
                </hb-data-table-header>
                <v-row v-if="rentPlans.length" class="rent-card-wrap px-3 mt-4">
                  <RentCard
                    v-for="(plan, index) in rentPlans"
                    :key="index"
                    :rent-plan="plan"
                    @setActive="updatePlanStatus"
                    :editPermission="rmPermissions.manage_rent_plans"
                    @delete="deleteRentPlan"
                    @fetchPlans="fetchData()"
                  />
                  <div
                    v-if="rmPermissions.manage_rent_plans"
                    class="create-plan-wrapper d-flex justify-center align-center"
                  >
                    <div class="create-plan-card-placeholder-wrapper">
                      <div class="hb-text-night-light">
                        Let us add a default rent plan for easier use.
                      </div>
                      <hb-btn
                        class="ma-2"
                        @click="showCreatePlanForm = !showCreatePlanForm"
                      >
                        Create New Rent Plan
                      </hb-btn>
                    </div>
                  </div>
                </v-row>
              </div>
              <!-- Form for create new rent plan -->
              <RentPlanForm
                v-model="showCreatePlanForm"
                ref="newPlanForm"
                :rent="defaultPlan"
                @submit="createPlan"
              />
            </template>
            <template v-if="selectedSideTab == 'rentPlanDefaults'">
              <RentPlanDefaults
                :has-caution-bar="showRentPlansNotification"
                :rmPermissions="rmPermissions"
              />
            </template>
          </div>
        </div>
      </template>
    </right-side-navigation>
  </div>
</template>

<script>
import RentCard from "./RentCard.vue";
import api from "../../../assets/api.js";
import { cloneDeep, startCase } from "lodash";
import RentPlanForm from "./RentPlanForm.vue";
import RightSideNavigation from "../utils/RightSideNavigation.vue";
import RentPlanDefaults from "./RentPlanDefaults.vue";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import { EventBus } from "../../../EventBus.js";

export default {
  name: "RentPlansLibrary",
  components: {
    RentCard,
    RentPlanForm,
    RightSideNavigation,
    RentPlanDefaults,
  },
  mixins: [notificationMixin],
  props: {
    rmPermissions: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      cautionBar: true,
      initialDataLoading: false,
      rent: [],
      showCreatePlanForm: false,
      defaultPlan: {
        name: "",
        description: "",
        tags: [],
        disable_pre_pay_rent_raise: false,
        maximum_rent_raise_percentage: "",
        round_to: null,
        settings: [],
        active: false,
      },
      selectedSideTab: "rentPlanLibrary",
      rightSideMenuItems: [
        {
          title: "Rent Plan Library",
          id: "rentPlanLibrary",
        },
        {
          title: "Rent Plan Defaults",
          id: "rentPlanDefaults",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      selectedProperty: "revManStore/getSelectedProperty",
      hasInternalRentEngine: "revManStore/hasInternalRentEngine",
      rentEngine: "revManStore/rentEngine",
      getPropertyRentSettings: "revManStore/getPropertyRentSettings",
    }),
    ...mapState({
      rentPlans: (state) => state.revManStore.rent.plans ?? [],
      hasAutomationEnabled: (state) => state.revManStore.rent.automation,
    }),
    hasNoRentPlans() {
      return !this.rentPlans.length;
    },
    hasPropertyAutomationEnabled() {
      return (
        this.selectedProperty &&
        this.getPropertyRentSettings &&
        this.getPropertyRentSettings?.automation_enabled_by_admin
      );
    },
    showRentPlansNotification() {
      return (
        this.rmPermissions.view_rent_plans &&
        this.cautionBar &&
        this.selectedSideTab != "rentPlanDefaults" &&
        this.hasInternalRentEngine &&
        this.hasPropertyAutomationEnabled &&
        !this.hasNoRentPlans
      );
    },
    showEmptyStateView() {
      return (
        !this.hasAutomationEnabled ||
        !this.rmPermissions.view_rent_plans ||
        this.hasNoRentPlans ||
        !this.hasInternalRentEngine ||
        (this.selectedProperty && !this.hasPropertyAutomationEnabled)
      );
    },
    showAddButton() {
      const commonConditions =
        this.rmPermissions.view_rent_plans && this.hasNoRentPlans && this.hasAutomationEnabled;
      return (
        (this.selectedProperty &&
          this.hasPropertyAutomationEnabled &&
          this.hasInternalRentEngine &&
          commonConditions) ||
        (!this.selectedProperty && commonConditions)
      );
    },
    planPlaceholderMsg() {
      let msg = "";
      // Check if the user has permission to view rent plans
      if (this.rmPermissions.view_rent_plans && this.hasAutomationEnabled) {
        // Check if a property is selected
        if (this.selectedProperty) {
          // Check if automation is enabled
          if (!this.hasPropertyAutomationEnabled) {
            msg =
              "You do not have the permission to access this page. Please contact the Admin.";
          } else if (!this.hasInternalRentEngine) {
            // Assign a message stating that the user cannot access the page because they are using a different rent engine
            msg = `Since you are using ${startCase(
              this.rentEngine
            )}, you cannot access this page.`;
          } else if (this.hasNoRentPlans) {
            // Checks if there are no rent plans
            msg = "Let us add a default rent plan for easier use.";
          }
        } else if (this.hasNoRentPlans) {
          msg = "Let us add a default rent plan for easier use.";
        }
      } else
        msg =
          "You do not have the permission to access this page. Please contact the Admin.";
      return msg;
    },
  },
  watch: {
    showCreatePlanForm(flag) {
      if (!flag) this.$refs.newPlanForm.resetForm();
    },
  },
  methods: {
    ...mapActions({
      fetchRentPlans: "revManStore/fetchRentPlans",
    }),
    ...mapMutations({
      setRentPlans: "revManStore/SET_RENT_PLANS",
    }),

    /**
     * Function for delete rent plan.
     * @param {String} id id of the plan
     * @param {Function} callback function to close the confirmation model window
     * and the button loader
     */
    async deleteRentPlan({ id, callback }) {
      let plan = this.rentPlans?.find((item) => item.id === id);
      try {
        await api.delete(this, api.COMPANY_RENT_MANAGEMENT + `plans/${id}/`);
        this.showMessageNotification({
          type: "success",
          description: `Rent Plan ${plan?.name} has been deleted.`,
        });
        await this.fetchData();
        if (this.selectedProperty)
          EventBus.$emit("tenantRentManagementEvent", { event: 'refreshTable' })
      } catch (error) {
        let assignedTo = ['lease', 'space group', 'space type'].find(word => error.includes(word)) ?? "";
        this.showMessageNotification({
          description: `You cannot delete the "${plan?.name}" rent plan since it is currently assigned to a ${assignedTo}. 
          To delete it, you must first unassign it from the ${assignedTo}.`
        });
      }
      callback();
    },

    /**
     * Function for fetch all rent plan list.
     */
    async fetchData() {
      try {
        await this.fetchRentPlans();
      } catch (err) {
        console.error("Rent plan fetch error", err);
        this.showMessageNotification({
          description: "Rent Plans fetch failed.",
        });
      }
    },

    /**
     * Function for create new rent plan.
     * @param {Object} data payload of the new plan
     * @param {Function} callback function to close the button loader
     */
    async createPlan(data) {
      try {
        this.initialDataLoading = true;
        let response = await api.post(
          this,
          api.COMPANY_RENT_MANAGEMENT + "plans",
          data
        );
        this.setRentPlans(response.rent_management_plan);
        await this.fetchData();
        this.initialDataLoading = false;
        this.showMessageNotification({
          type: "success",
          description: `Rent plan ${data?.name} successfully created`,
        });
      } catch (error) {
        console.log(error);
        this.showMessageNotification({
          description: error?.includes("already exists") ? error : `Unable to create Rent Plan.`,
        });
        await this.fetchData();
        this.initialDataLoading = false
      }
    },

    /**
     * Function for update plan status.
     * @param {Object} data payload of the corresponding plan which needs to be
     * set as active or inactive
     * @param {Function} callback function to close the confirmation model window
     * and the button loader
     */
    async updatePlanStatus({ data, callback }) {
      try {
        let rentPlan = cloneDeep(data);
        rentPlan.active = !data.active;
        delete rentPlan?.id;
        delete rentPlan?.created_by;
        await api.put(
          this,
          api.COMPANY_RENT_MANAGEMENT + `plans/${data.id}/`,
          rentPlan
        );
        this.fetchData();
        this.showMessageNotification({
          type: "success",
          description: `Rent plan ${data?.name} Status successfully updated`,
        });
        callback();
      } catch (error) {
        console.log(error);
        this.showMessageNotification({
          description: `Unable to update rent plan status.`,
        });
        await this.fetchData()
        this.initialDataLoading = false
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rent-card-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px;
  @media (max-width: 1870px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 1310px) {
    grid-template-columns: 1fr;
  }

  .create-plan-wrapper {
    min-height: 447px;
    border: 1px dashed #c4cdd5 !important;
    background-color: transparent !important;
    border-radius: 4px;

    .create-plan-card-placeholder-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .loader-card {
    width: 100%;
  }
}
</style>
