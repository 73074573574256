<template>
  <div>
    <rent-kebab-view
      :items="filteredItems"
      :parent-event="parentEvent"
      :params-data="params.data"
    />
  </div>
</template>
<script>
import RentKebabView from "./RentKebabView.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "TenantRentKebabFilter",
  props: {
    parentEvent: {
      type: String,
      required: true,
      default: "",
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    RentKebabView,
  },
  computed: {
    ...mapState({
      rentPlans: (state) => state.revManStore?.rent?.plans ?? [],
    }),
    ...mapGetters({
      hasInternalRentEngine: "revManStore/hasInternalRentEngine",
      getPropertyRentSettings: "revManStore/getPropertyRentSettings",
    }),

    filteredItems() {
      let paramsData = this.params?.data;
      const rentExemptLabel =
        paramsData?.lease_rent_plan_status === "exempt"
          ? "Remove Rent Change Exemption"
          : "Exempt from Rent Change";
      const assignRentPlanText = paramsData?.tenant_rent_plan_id
        ? "Change Rent Plan"
        : "Assign Rent Plan";
      let items = [
        ...(this.rentPlans.length &&
        paramsData?.lease_rent_plan_status !== "exempt" &&
        this.hasInternalRentEngine &&
        this.permissions.assign_rent_plan &&
        this.getPropertyRentSettings?.automation_enabled_by_admin 
          ? [
              {
                text: assignRentPlanText,
                event: "changeRentPlan",
              },
            ]
          : []),
        ...(this.permissions.exempt_rent_changes
          ? [
              {
                text: rentExemptLabel,
                event: "rentChangeExempt",
              },
            ]
          : []),
        ...(this.permissions.manual_rent_changes 
          ? [
              {
                text: "Edit Rent Change",
                event: "editNextRent",
              },
            ]
          : []),
        ...(this.permissions.manage_rent_change_status
          ? [
              {
                text: "Skip/Cancel Rent Change",
                event: "skipNextRent",
              },
            ]
          : []),
        ...(this.permissions.manual_rent_changes
          ? [
              {
                text: "Manual Rent Change",
                event: "manualRentChange",
              },
            ]
          : []),
      ];
      return items;
    },
  },
};
</script>
